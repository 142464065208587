import { createStore } from "vuex";
import axios from "axios";

const state = () => ({
	currentTab: "",
	modalOpen: false,
	modalTrigger: "",
	modalContent: "",
	modalProps: {},
	modalCloseButton: true,
	vfsModalOpen: false,
	vfsUrl: "",
	eventIndexYear: new Date().getFullYear(),
	eventIndexMonth: new Date().getMonth() + 1,
	eventIndexDay: new Date().getDate(),
	instanceAvailability: "Returns Only",
	instancePriority: 0,
	instanceCount: 0,
	lastInstanceDone: false,
	loggedIn: false,
	spektrixBasket: null,
	spektrixBasketPromise: null,
	spektrixCustomer: null,
	spektrixCustomerPromise: null,
	eventData: null,
	eventDataPromise: null,
});

// getters
const getters = {};

// actions
const actions = {
	setActiveTab(context, data) {
		context.commit("saveActiveTab", {
			name: data.name,
		});
	},
	setInstanceAvailability: ({ commit }, data) =>
		commit("setInstanceAvailability", data),
	openModal: ({ commit }, elem) => commit("openModal", elem),
	closeModal: ({ commit }) => commit("closeModal"),

	openVfsModal: ({ commit }, url) => commit("openVfsModal", url),
	closeVfsModal: ({ commit }) => commit("closeVfsModal"),

	changeModalContent: ({ commit }, options) =>
		commit("changeModalContent", options),
	eventIndexYear: ({ commit }, year) => commit("eventIndexYear", year),
	eventIndexMonth: ({ commit }, month) => commit("eventIndexMonth", month),
	eventIndexDay: ({ commit }, day) => commit("eventIndexDay", day),
	loggedIn: ({ commit }, status) => commit("loggedIn", status),
	async getSpektrixBasket({ state, commit }, data) {
		//if spektrix data already exists, do nothing
		if (state.spektrixBasket) {
			return state.spektrixBasket;
		}

		//if a promise to get spektrix data already exists, do nothing
		if (state.spektrixBasketPromise) {
			return state.spektrixBasketPromise;
		}

		// // if data already exists in session storage, update our vue store
		// const spektrixBasketSessionCache = sessionStorage.getItem('spektrixBasket');
		// const spektrixBasketSessionCacheTime = sessionStorage.getItem('spektrixBasketCache');

		// if (spektrixBasketSessionCacheTime) {
		//   let parsedDate = new Date(spektrixBasketSessionCacheTime);
		//   let currentDate = new Date();

		//   // if current date is less than than cache date, then it is valid and we can update the spektrix basket using session storage
		//   if (currentDate <= parsedDate) {
		//     if (spektrixBasketSessionCache) {

		//       commit('setBasketData', JSON.parse(spektrixBasketSessionCache));

		//       return;
		//     }
		//   }
		// }

		// else, go get spektrix date from spektrix itself
		let apiPromise = axios
			.get(
				data.url +
					"/api/v3/basket?$expand=tickets/event,tickets/instance,tickets/ticketType",
				{
					withCredentials: true,
				},
			)
			.then((response) => {
				console.log("vueStore.getSpektrixBasket.response", response);

				// //session storage update
				// let currentDate = new Date();
				// currentDate.setMinutes(currentDate.getMinutes() + 10);

				// sessionStorage.setItem('spektrixBasket', JSON.stringify(response.data));
				// sessionStorage.setItem('spektrixBasketCache', currentDate);

				commit("setBasketData", response.data);
			})
			.catch((error) => {
				console.log("vueStore.getSpektrixBasket.error", error);
				return "Error connecting to spektrix";
			});

		commit("setBasketPromise", apiPromise);
		return apiPromise;
	},
	async getSpektrixCustomer({ state, commit }, data) {
		//if spektrix data already exists, do nothing
		if (state.spektrixCustomer) {
			return state.spektrixCustomer;
		}

		//if a promise to get spektrix data already exists, do nothing
		if (state.spektrixCustomerPromise) {
			return state.spektrixCustomerPromise;
		}

		// // if data already exists in session storage, update our vue store
		// const spektrixBasketSessionCache = sessionStorage.getItem('spektrixBasket');
		// const spektrixBasketSessionCacheTime = sessionStorage.getItem('spektrixBasketCache');

		// if (spektrixBasketSessionCacheTime) {
		//   let parsedDate = new Date(spektrixBasketSessionCacheTime);
		//   let currentDate = new Date();

		//   // if current date is less than than cache date, then it is valid and we can update the spektrix basket using session storage
		//   if (currentDate <= parsedDate) {
		//     if (spektrixBasketSessionCache) {

		//       commit('setBasketData', JSON.parse(spektrixBasketSessionCache));

		//       return;
		//     }
		//   }
		// }

		// else, go get spektrix date from spektrix itself
		let apiPromise = axios
			.get(data.url + "/api/v3/customer?$expand=tags", {
				withCredentials: true,
			})
			.then((response) => {
				console.log("vueStore.getSpektrixCustomer.response", response);

				// //session storage update
				// let currentDate = new Date();
				// currentDate.setMinutes(currentDate.getMinutes() + 10);

				// sessionStorage.setItem('spektrixBasket', JSON.stringify(response.data));
				// sessionStorage.setItem('spektrixBasketCache', currentDate);

				commit("setCustomerData", response.data);
			})
			.catch((error) => {
				//spetrix errors when not logged in, so we have to assume thats the reasone theres an error!
				//console.log('vueStore.getSpektrixCustomer.error', error);
				return "Not logged in";
			});

		commit("setCustomerPromise", apiPromise);
		return apiPromise;
	},
	async getEventData({ state, commit }, data) {
		//if spektrix data already exists, do nothing
		if (state.eventData) {
			return state.eventData;
		}

		//if a promise to get spektrix data already exists, do nothing
		if (state.eventDataPromise) {
			return state.eventDataPromise;
		}

		// else, go get spektrix date from spektrix itself
		let apiPromise = axios
			.get("/actions/event-manager/availability/event?id=" + data.id)
			.then((response) => {
				//console.log('vueStore.getEventData.response', response);

				commit("setEventData", response.data);
			})
			.catch((error) => {
				return "Event id not found";
			});

		commit("setEventPromise", apiPromise);
		return apiPromise;
	},
};

// mutations
const mutations = {
	saveActiveTab(state, payload) {
		state.currentTab = payload.name;
	},
	openModal(state, elem) {
		state.modalOpen = true;
		state.modalTrigger = elem;
	},
	openVfsModal(state, url) {
		state.vfsModalOpen = true;
		state.vfsUrl = url;
	},
	closeModal(state) {
		console.log("test", state);
		state.modalOpen = false;
	},
	closeVfsModal(state) {
		state.vfsModalOpen = false;
		state.vfsUrl = "";
	},
	setInstanceAvailability(state, data) {
		state.instanceAvailability = data.value;
		state.instancePriority = data.priority;
		state.instanceCount++;

		if (state.instanceCount == data.instanceAmount) {
			state.lastInstanceDone = true;
		}
	},
	changeModalContent(state, options) {
		if (options.close == null) {
			state.modalCloseButton = true;
		} else {
			state.modalCloseButton = options.close;
		}
		state.modalContent = options.component;
		state.modalProps = options.props;
	},
	eventIndexYear(state, year) {
		state.eventIndexYear = year;
	},
	eventIndexMonth(state, month) {
		state.eventIndexMonth = month;
	},
	eventIndexDay(state, day) {
		state.eventIndexDay = day;
	},
	loggedIn(state, status) {
		state.loggedIn = status;
	},
	setBasketData(state, data) {
		state.spektrixBasket = data;
	},
	setBasketPromise(state, data) {
		state.spektrixBasketPromise = data;
	},
	setCustomerData(state, data) {
		state.spektrixCustomer = data;
	},
	setCustomerPromise(state, data) {
		state.spektrixCustomerPromise = data;
	},
	setEventData(state, data) {
		state.eventData = data;
	},
	setEventPromise(state, data) {
		state.eventDataPromise = data;
	},
};

export const store = createStore({
	state,
	getters,
	actions,
	mutations,
});
